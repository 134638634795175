import React from "react";
import AmazonButton from "./AmazonButton";
import AmazonCover from "./AmazonCover";

export default function AmazonListing(props) {
  return (
    <div className="tile is-parent">
      <div className="tile is-child card">
        <AmazonCover title={props.title} coverPath={props.coverPath} />
        <hr />
        <div className="card-content">
          <div className="content">
            <h3 className="subtitle has-text-primary has-text-centered">
              {props.title}
            </h3>
            <p>{props.blurb}</p>
          </div>
          <AmazonButton amazonLink={props.amazonLink} />
        </div>
      </div>
    </div>
  );
}
