import React from "react";

export default function Friends() {
  return (
    <>
      <h3 className="subtitle has-text-primary has-text-centered">
        Tell Me the Names of Your Friends,
        <br />
        and I Will Tell You Who You Are
        <br />
        <em>(Arab Proverb)</em>
      </h3>
      <p>
        The story of Lee Sturgeon Day&apos;s year without a voice told through
        letters she exchanged with friends.
      </p>
      <blockquote>
        &quot;Beautiful book! Wow. What a fantastic idea. What a labor of love…&quot;
      </blockquote>
      <blockquote>
        &quot;Looks like you did it again! What an original format and how can you
        get more real about destiny…?&quot;
      </blockquote>
      <blockquote>
        &quot;Love the format..also highly adaptable to film. This could be an Oprah
        project…&quot;
      </blockquote>
      <blockquote>
        &quot;Brilliant. Inspired and inspiring, and such an artistic approach to
        healing.&quot;
      </blockquote>
      <blockquote>
      &quot;Dear Mum,
        <br />
        Last night we had a huge haboob, great piles of rain and sand poured on
        our house, electricity was down, and for some reason trucks filled with
        ululuating women drove up and down the streets (presumably celebrating
        the sale of your house). So I read your book by candlelight–got through
        the whole thing in one sitting. It&apos;s absolutely wonderful, very sad and
        very funny at exactly the same time. How honored I feel to be so central
        to the book, even though many of my emails to you during that period
        were more to do with my silly bar exam than with your much more
        important throat. If I had to write a book along the lines of yours,
        every chapter would be dominated by your emails and calls and letters
        and chocolate filled care packages. There has never been a moment in my
        life when I haven&apos;t felt completely, unconditionally, and freely loved
        by you. If those are the capacities that 45 years of woowoo training has
        given you, then I should probably send some pretty big thanks out to
        Steiner, wherever he is!
        <br />
        Lots of love, Adam.&quot; (from Darfur)
      </blockquote>
      <blockquote>
        &quot;This book is a fascinating eloquent and inspiring account of the power
        of a circle of friends to sustain the courageous victim of medical
        malpractice through a long dark period of her life.&quot;
      </blockquote>
      <blockquote>
        &quot;Lee Sturgeon Day is a fellow traveler on our path of life who takes an
        immense blow, an immense loss, with spiritual wisdom. Through humor,
        through tears, but always with a sense of truth, Lee writes like a
        modern-day warrior we can all relate to…&quot;
      </blockquote>
    </>
  );
}
