import React from "react";

export default function Wibbly() {
  return (
    <>
      <h3 className="subtitle has-text-primary has-text-centered">
        Meet the Wibbly Bibs
      </h3>
      <p>
        Two little girls who have adventures that take them into other worlds.
        Sometimes rocket ships are flown, and sometimes magical woodland
        characters are met. A collection of stories written and illustrated with
        colored pencil drawings by the Grandmother of two REAL little girls.
      </p>
      <p>Stories:</p>
      <ol>
        <li>Meet the Wibbly Bibs</li>
        <li>Hettie Hobnob Comes to Call</li>
        <li>The Wait, Wonder and Welcome Place</li>
        <li>Grandma and the Gremlin</li>
        <li>The Wibbly Bibs and the Magic Carpet</li>
      </ol>
    </>
  );
}
