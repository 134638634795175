import React from "react";

export default function Footer() {
  return (
    <footer className="footer has-background-white">
      <div className="container">
        <div className="content has-text-centered">
          <p className="help">
            A&nbsp;
            <a
              href="https://yeager.works"
              target="_blank"
              rel="noopener noreferrer"
            >
              Yeager Works
            </a>&nbsp;Project
          </p>
        </div>
      </div>
    </footer>
  );
}
