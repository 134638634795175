import React from "react";
import Navbar from "../components/Navbar";
import Tabs from "../components/Tabs";
import Footer from "../components/Footer";

export default function Lifeways() {
  return (
    <>
      <Navbar />
      <Tabs />
      <main className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              <article className="notification has-background-white content">
                <h1 className="title has-text-primary">Lifeways</h1>
                <p>
                  <strong>LIFEWAYS for Healing Education</strong> seeks to bring
                  deeper understanding and practical ways of working with the
                  questions and challenges that confront us today as individuals
                  and as members of our communities. All this work is inspired
                  by Anthroposophy.
                </p>
                <p>
                  Lee offers workshops for adults and retreats for schools and
                  other communities. She teaches in several Waldorf Teacher
                  Training programs. (She was a Waldorf parent for 13 years).
                </p>

                <p>
                  She has written on many themes of adult development and
                  parenting. (Her chapter on single-parenting appears in{" "}
                  <em>More Lifeways</em>, Hawthorn Press, and a chapter on her
                  own work in <em>Remembering Wholeness</em> by Julia Hope Price
                  & Jozef: Divine Imaging; info@Divineimaging.org)
                </p>

                <h3>Some Themes:</h3>
                <ul>
                  <li>Personal Biography/Life Cycles</li>
                  <li>Nurturing Relationships</li>
                  <li>Using Our Imagination</li>
                  <li>Finding Balance in Daily Life</li>
                  <li>Double Trouble</li>
                  <li>Movement (Form) Drawing as a Path of Development</li>
                </ul>
                <p>(Fees available on request through the contact email).</p>
              </article>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
