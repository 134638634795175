import React from "react";
import PaypalButton from "../components/PaypalButton";

export default function Packs(props) {
  return (
    <>
      <div className="tile is-parent">
        <div className="tile is-child box content">
          <h3 className="subtitle has-text-centered has-text-secondary">
            <em>{props.title}</em> &#8211; &#36;{props.price}
          </h3>
          <p className="has-text-centered">{props.blurb}</p>
          <PaypalButton paypalTag={props.paypalTag} />
        </div>
      </div>
    </>
  );
}
