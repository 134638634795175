import React from "react";
import PaypalButton from "./PaypalButton";

export default function Memoir() {
  return (
    <>
      <div className="box content">
        <h1 className="title has-text-primary has-text-centered">
          Not Half Bad
        </h1>
        <h3 className="subtitle has-text-centered has-text-secondary">
          &#8211; $12
        </h3>
        <p>
          Lee's memoir from birth to 70. Lee has been asked by several people
          for this, so she is happy to make it available as a download.
        </p>
        <PaypalButton paypalTag={"5567BPYB78ABE"} />
      </div>
    </>
  );
}
