import React from "react";

export default function PaypalButton(props) {
  return (
    <>
      <hr />
      <p className="has-text-centered has-text-info">
        <em>Pay with credit card via PayPay Checkout.</em>
      </p>
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
        className="has-text-centered"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value={props.paypalTag} />
        <button className="button is-paypal is-outlined" type="submit">
          <span className="icon is-small">
            <i className="material-icons">cloud_download</i>
          </span>
          <span>Pay &amp; Download</span>
        </button>
      </form>
    </>
  );
}
