import React from "react";

export default function Turtle() {
  return (
    <>
      <h3 className="subtitle has-text-primary has-text-centered">
        A Turtle Tale
      </h3>
      <p>
        A tale of a family of turtles who have to leave their river, and find a
        new place to call home. This story was written for Jad age 4 from
        Damascus, Syria, who had to leave his home and his turtles behind in
        2013.
      </p>
      <p className="has-text-centered">***</p>
      <p>
        <em>
          I am sitting with my family now in Damascus reading it in the garden,
          with the turtles out and about all around us, and Jad is running
          through the water sprinklers with his siblings!! We are happy to be
          home for the summer and now we are even happier to be in your book!!!
        </em>
      </p>
      <p>
        <em>
          Thank you Lee!! Thank you for seeing beyond our faces and right into
          our pain, into our memories, and into our hearts.
          <br />
          Lots of love!! <strong>Mina and Jad!!!</strong>
        </em>
      </p>
    </>
  );
}
