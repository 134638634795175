import React from "react";
import Navbar from "../components/Navbar";
import Tabs from "../components/Tabs";
import Footer from "../components/Footer";
import AmazonListing from "../components/AmazonListing";
import AmazonPromo from "../components/AmazonPromo";
import Friends from "../components/texts/Friends";
import Slice from "../components/texts/Slice";
import Turtle from "../components/texts/Turtle";
import Wibbley from "../components/texts/Wibbley";

import BiographyImg from "../images/biography-800x600.jpg";
import BitiImg from "../images/biti-800x600.jpg";
import CommunityImg from "../images/community-800x600.jpg";
import DoubleImg from "../images/double-800x600.jpg";
import FriendsImg from "../images/friends-800x600.jpg";
import MovementImg from "../images/movement-800x600.jpg";
import NurturingImg from "../images/nurturing-800x600.jpg";
import PlanetImg from "../images/planet-800x600.jpg";
import SliceImg from "../images/slice-800x600.jpg";
import SophiaImg from "../images/sophia-800x600.jpg";
import TurtleImg from "../images/turtle-800x600.png";
import WibblyImg from "../images/wibbly-800x600.png";

export default function Books() {
  return (
    <>
      <Navbar />
      <Tabs />
      <section className="section">
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            <div className="content">
              <article className="notification has-background-white content has-text-centered">
                <h1 className="title has-text-primary">
                  Available now on <em>Amazon.com</em>
                </h1>
              </article>
            </div>
          </div>
        </div>
        {/* end columns */}
        <div className="tile is-ancestor">
          <AmazonPromo
            title={"A Slice of Life"}
            content={<Slice />}
            coverPath={SliceImg}
            amazonLink={
              "https://www.amazon.com/Slice-Life-Lee-Sturgeon-Day/dp/1691549843/"
            }
          />
          <AmazonPromo
            title={
              "Tell Me the Names of Your Friends, and I Will Tell You Who You Are"
            }
            content={<Friends />}
            coverPath={FriendsImg}
            amazonLink={
              "https://www.amazon.com/Tell-Names-Your-Friends-Will/dp/1608444074/"
            }
          />
        </div>
        {/* end tiles */}
        <div className="tile is-ancestor">
          <AmazonPromo
            title={"Meet the Wibbly Bibs"}
            content={<Wibbley />}
            coverPath={WibblyImg}
            amazonLink={
              "https://www.amazon.com/Meet-Wibbly-Bibs-Lee-Sturgeon/dp/1544186851/"
            }
          />
          <AmazonPromo
            title={"A Turtle Tale"}
            content={<Turtle />}
            coverPath={TurtleImg}
            amazonLink={
              "https://www.amazon.com/Turtle-Tale-Lee-Sturgeon-Day/dp/1545017107/"
            }
          />
        </div>
        {/* end tiles */}
        <div className="tile is-ancestor">
          <AmazonListing
            title={"Biti, Kuti and Ku"}
            blurb={
              '"Far far away in Africa there is a country with so many trees you hardly ever see the sky. You cannot see the sun by day. Or the moon by night. And even where there is a gap in the trees, often the sky is filled with big grey clouds that hang over the trees and the land beneath and you still cannot see the sun or the moon or the stars. There are big storms with thunder and lightning and after that it rains for days and days, and then you can’t even see the trees, there is so much water running down your face and into your eyes..."'
            }
            coverPath={BitiImg}
            amazonLink={
              "https://www.amazon.com/Biti-Kuti-Lee-Sturgeon-Day/dp/1727462882/"
            }
          />
          <AmazonListing
            title={"Sophia and the Banyan Tree"}
            blurb={
              "As soon as Sophia could walk she would set forth across the village green in Hawi, Hawaii, to the great Banyan tree and start climbing up it as if it was just a rather steep hill. In bare feet, of course. She inspired this story, illustrated by hand in beautiful, full-page colored pencil drawings."
            }
            coverPath={SophiaImg}
            amazonLink={
              "https://www.amazon.com/Sophia-Banyan-Tree-Lee-Sturgeon/dp/1986283240/"
            }
          />
        </div>
        {/* end tiles */}
        <div className="tile is-ancestor">
          <AmazonListing
            title={"Biography/Life Cycles"}
            blurb={
              "Describes the 7yr life phases from birth through 63, the mirroring and recapitulation of childhood phases in later adulthood. Shows how to draw the Life Chart, and offers many biographical exercises for individuals and groups."
            }
            coverPath={BiographyImg}
            amazonLink={
              "https://www.amazon.com/Biography-Life-Cycles-Workbook-Sturgeon/dp/1099017181/"
            }
          />
          <AmazonListing
            title={"Nurturing Relationships Workbook"}
            blurb={
              "Offers insights and approaches to the field of human relationships, with many practical and imaginative exercises for individuals, couples and groups. Destiny and karma is also addressed. A chapter on single parenting is included."
            }
            coverPath={NurturingImg}
            amazonLink={
              "https://www.amazon.com/Nurturing-Our-Relationships-Workbook-Sturgeon/dp/1099026261/"
            }
          />
          <AmazonListing
            title={"Double-Trouble Workbook"}
            blurb={
              'There are many aspects of the human being that await conscious transformation and these can lead to conflicts between us, between groups and nations. This workbook describes the polar "temptations" we all walk between, and 7 regions of our nature in which our "doubles" influence our behaviors.'
            }
            coverPath={DoubleImg}
            amazonLink={
              "https://www.amazon.com/Double-Trouble-Workbook-Lee-Sturgeon-Day/dp/109902322X/"
            }
          />
        </div>
        {/* end tiles */}
        <div className="tile is-ancestor">
          <AmazonListing
            title={"Movement Drawing Workbook"}
            blurb={
              "A simple introduction to the art of the moving line, a practice in many ancient mystery centers, and a wonderfully harmonizing and energizing activity for our times."
            }
            coverPath={MovementImg}
            amazonLink={
              "https://www.amazon.com/Movement-Drawing-Workbook-Lee-Sturgeon/dp/1099023777/"
            }
          />
          <AmazonListing
            title={"Creating Community Workbook"}
            blurb={
              "We live in a community with our environment, the people in our lives, those who have died and spiritual beings who surround and influence us. This workbook offers many insights and approaches to developing and enhancing the web of connections that sustains each of us as individuals and members of a world community."
            }
            coverPath={CommunityImg}
            amazonLink={
              "https://www.amazon.com/Creating-Community-Workbook-Lee-Sturgeon/dp/1099021405/"
            }
          />
          <AmazonListing
            title={"Planetary Qualities for Working Groups"}
            blurb={
              "Describes the planetary qualities that emerge in adolescence and have a profound effect on our working together as adults. Learning to distinguish our own primary qualities and those of others in our working communities (families, schools etc) can enable us to function more creatively as an individual and in working groups."
            }
            coverPath={PlanetImg}
            amazonLink={
              "https://www.amazon.com/Planetary-Qualities-Working-Groups-Sturgeon/dp/1099026393/"
            }
          />
        </div>
        {/* end tiles */}
      </section>
      <Footer />
    </>
  );
}
