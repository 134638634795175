import React from "react";
import Navbar from "../components/Navbar";
import Tabs from "../components/Tabs";
import Footer from "../components/Footer";
import Profile from "../images/lee-3x2.png";
import Decor from "../images/footer-decor.jpg";

export default function Home() {
  return (
    <>
      <Navbar />
      <Tabs />
      <main className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              <figure className="image is-3by2">
                <img src={Profile} alt="Lee Day" title="Lee Day" />
              </figure>
              <br />
              <article className="notification has-background-white content">
                <p>
                  Lee Sturgeon Day was born in England in 1941 and educated in
                  England, Italy and France. She was personal assistant to Sir
                  John Betjeman (Britain's Poet Laureate) for 3 years. Other
                  activities have included: traveling, writing food guides,
                  managing a London Coffee House, therapy and social work,
                  farming, and flamethrowing in a circus.
                </p>
                <p>
                  Lee is a counselor and adult educator, co-founder of the
                  Center for Social Development, England, based on the work of
                  Rudolf Steiner, and Lifeways for Healing Education, Detroit.
                  She has led workshops since l975, and taught in many Waldorf
                  Teacher Training programs.
                </p>
                <p>
                  In 1987 she was diagnosed with breast cancer. Her book, A
                  SLICE OF LIFE, describes her journey towards healing through
                  anthroposophic medicine, notably mistletoe therapy, widely and
                  successfully used in Europe. Mistletoe therapy was approved as
                  a homeopathic remedy, and now, 33yrs later, is going through
                  the first clinical trials at Johns Hopkins for treating
                  cancer. The second edition of{" "}
                  <a
                    href="https://www.amazon.com/Slice-Life-Lee-Sturgeon-Day/dp/1691549843/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    A Slice of Life
                  </a>{" "}
                  is now available on Amazon.com, together with a Kindle
                  edition.
                </p>
                <p>
                  Several years ago, a minor surgery led to damage to Lee's
                  vocal cords and a total loss of her voice for 2 years. TELL ME
                  THE NAMES OF YOUR FRIENDS AND I WILL TELL YOU WHO YOU ARE
                  (Arab proverb) is an account of this journey through her email
                  correspondence with her friends.
                </p>
                <p>
                  Lee currently lives in California. She has a son, Adam, and
                  two granddaughters.
                </p>
                <p>
                  (Information on anthroposophic medicine, and other work
                  arising from Rudolf Steiner's insights can be found on line at{" "}
                  <a
                    href="https://anthroposophy.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    anthroposophy.org
                  </a>
                  .)
                </p>
                <hr />
                <h2 className="has-text-centered">Contact</h2>
                <p className="has-text-centered">
                  Please contact Lee via email:
                </p>
                <p className="has-text-centered">
                  <a
                    className="button is-primary is-outlined"
                    href="mailto:sturgeonday@gmail.com"
                  >
                    <span className="icon">
                      <i className="material-icons">email</i>
                    </span>
                    <span>Email Lee</span>
                  </a>
                </p>
              </article>
              <img src={Decor} alt="splash decor" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
