import React from "react";

export default function AmazonButton(props) {
  return (
    <>
      <hr />
      <p className="has-text-centered">
        <a
          className="button is-amazon"
          href={props.amazonLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="icon">
            <i className="material-icons">shopping_basket</i>
          </span>
          <span>Order on Amazon</span>
        </a>
      </p>
    </>
  );
}
