import React from "react";
import PaypalButton from "./PaypalButton";

export default function Rwanda() {
  return (
    <>
      <div className="box content">
        <h1 className="title has-text-primary has-text-centered">
          Rwanda Memoir
        </h1>
        <h3 className="subtitle has-text-centered has-text-secondary">
          &#8211; $12
        </h3>
        <p>
          In 2014, Lee went to Rwanda with a group led by Olivia Stokes Dreier
          of the <em>Karuna Center for Peacebuilding</em> which works
          extensively to bring healing after the genocide. This is Lee's
          illustrated memoir of those 10 days, meeting many of the people who
          suffered the genocide and many who have striven to bring healing. $12
          proceeds to go to Karuna.
        </p>
        <p>Learn more:</p>
        <p className="has-text-centered">
          <a
            className="button is-primary is-outlined"
            href="https://www.karunacenter.org/project/rwanda/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon is-small">
              <i className="material-icons">link</i>
            </span>
            <span>Karunacenter.org</span>
          </a>
        </p>
        <PaypalButton paypalTag={"F6ATQNGHQENJQ"} />
      </div>
    </>
  );
}
