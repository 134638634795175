import React from 'react';
import Navbar from '../components/Navbar';
import Tabs from '../components/Tabs';
import Footer from '../components/Footer';
import Rwanda from '../components/Rwanda';
import Packs from '../components/Packs';
import Memoir from '../components/Memoir';

export default function Writings(){
  return(
    <>
    <Navbar />
    <Tabs />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            <Memoir />
            <Rwanda />
          </div>
        </div> {/*  end columns */}
      </div>
    </section>
  
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            <article className="notification has-background-white content ">
              <h1 className="title has-text-primary has-text-centered">Workbooks</h1>
              <h3 className="has-text-centered">Instant PDF Download!</h3>
              <p>
                These workbooks have distilled insights and approaches of Rudolf Steiner to these life themes and offer many practical and imaginative exercises that can be taken up individually or in groups.  They also offer extensive resources for those who might wish to pursue anthroposophy in greater depth. Once you have purchased a workbook, you will be automatically redirected to a new page where you will be able to download the workbook instantaneously. If you are not automatically redirected, please click “Return to Merchant” on the payment receipt page.
              </p>
              <hr/>
              <p>
              Interested in videos? Four 20 minute videos of Lee teaching the 7 year Life Cycles are available, contact her via email. Suggested cost $50.
              </p>
            </article>
          </div>
        </div> {/*  end columns */}
  
        <div className="tile is-ancestor">
        <Packs 
            title={'Biography/Life Cycles'} 
            price={'9'}
            blurb={'Describes the 7yr life phases from birth through 63, the mirroring and recapitulation of childhood phases in later adulthood.  Shows how to draw the Life Chart, and offers many biographical exercises for individuals and groups.'}
            paypalTag={'EFV8CEN8B4BJJ'} />
          <Packs 
            title={'Double-Trouble'} 
            price={'9'}
            blurb={'There are many aspects of the human being that await conscious transformation and these can lead to conflicts between us, between groups and nations.  This workbook describes the polar "temptations" we all walk between, and 7 regions of our nature in which our "doubles" influence our behaviors.  It offers many practical and artistic ways of working creatively with the challenges of our own doubles, and between ourselves and others.'}
            paypalTag={'TBENHZ7L4URB6'} />
          <Packs 
            title={'Nurturing Relationships'} 
            price={'9'}
            blurb={'Offers insights and approaches to the field of human relationships, with many practical and imaginative exercises for individuals, couples and groups.  Destiny and karma is also addressed.  A chapter on single parenting is included.'}
            paypalTag={'5UFDR4QNWXUX4'} />
        </div> {/*  end tiles */}
  
        <div className="tile is-ancestor">
        <Packs 
            title={'Creating Community'} 
            price={'9'}
            blurb={'We live in a community with our environment, the people in our lives, those who have died and spiritual beings who surround and influence us. This workbook offers many insights and approaches to developing and enhancing the web of connections that sustains each of us as individuals and members of a world community.'}
            paypalTag={'4NXJ365J8J868'} />
          <Packs 
            title={'Movement Drawing Workbook'} 
            price={'7'}
            blurb={'A simple introduction to the art of the moving line, a practice in many ancient mystery centers, and a wonderfully harmonizing and energizing activity for our times.'}
            paypalTag={'X8SMBKF5DANYU'} />
          <Packs 
            title={'Planetary Qualities for Working Groups'} 
            price={'7.50'}
            blurb={'Describes the planetary qualities that emerge in adolescence and have a profound effect on our working together as adults. Learning to distinguish our own primary qualities and those of others in our working communities (families, schools etc) can enable us to function more creatively as an individual and in working groups.'}
            paypalTag={'XV7N2AL8WPTDC'} />
        </div> {/*  end tiles */}
  
        <div className="tile is-ancestor">
          <Packs 
            title={'All Workbooks'} 
            price={'42'}
            blurb={'A total of 6!'}
            paypalTag={'7ZL9DHRP89X3G'} />
        </div> {/*  end tiles */}
      </div>
    </section>
    <Footer />
    </>
  );
}