import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo-sig.svg";

export default function NavBar() {
  return (
    <nav
      className="navbar has-shadow has-background-twitter"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <img
            className="image"
            src={Logo}
            alt="Lee Day"
            title="Lee Sturgeon Day"
            height="28px"
            width="156px"
          />
        </Link>
      </div>
    </nav>
  );
}
