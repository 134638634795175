import React from "react";
import { Link } from "react-router-dom";

export default function Tabs() {
  return (
    <section className="section">
      <div className="container">
        <div className="tabs is-centered">
          <ul>
            <li>
              <Link to="/" className="has-text-primary">
                Home
              </Link>
            </li>
            <li>
              <Link to="/books" className="has-text-primary">
                Books
              </Link>
            </li>
            <li>
              <Link to="/lifeways" className="has-text-primary">
                Lifeways
              </Link>
            </li>
            <li>
              <Link to="/writings" className="has-text-primary">
                Writings [Downloads]
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
