import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Books from '../pages/Books';
import Lifeways from '../pages/Lifeways';
import Writings from '../pages/Writings';

export default (
  <Router>
    <Routes>
      <Route path='/' exact element={<Home/>} />
      <Route path='/books' exact element={<Books/>} />
      <Route path='/Lifeways' exact element={<Lifeways/>} />
      <Route path='/writings' exact element={<Writings/>} />
    </Routes>
  </Router>
);
