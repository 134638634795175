import React from "react";
import AmazonButton from "./AmazonButton";
import AmazonCover from "./AmazonCover";

export default function AmazonPromo(props) {
  return (
    <div className="tile is-parent">
      <div className="tile is-child card">
        <AmazonCover title={props.title} coverPath={props.coverPath} />
        <hr />
        <div className="card-content">
          <div className="content">{props.content}</div>
          <AmazonButton amazonLink={props.amazonLink} />
        </div>
      </div>
    </div>
  );
}
