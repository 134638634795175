import React from "react";

export default function AmazonCover(props) {
  return (
    <div className="card-image">
      <figure className="image">
        <img
          src={props.coverPath}
          alt={props.title}
          title={props.title + " cover"}
          style={{ paddingTop: "10px" }}
        />
      </figure>
    </div>
  );
}
