import React from "react";

export default function Slice() {
  return (
    <>
      <h3 className="title has-text-primary has-text-centered">
        A Slice of Life
      </h3>
      <h4 className="subtitle has-text-primary has-text-centered">
        A Personal Story of Healing Through Cancer.
      </h4>
      <p>
        Now available in both <em>print</em> and <em>ebook</em> format, found on{" "}
        <a
          href="https://www.amazon.com/Slice-Life-Lee-Sturgeon-Day/dp/1691549843/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Amazon.com
        </a>
        .
      </p>

      <blockquote>
        Thank you, thank you, thank you for the book that you sent to me, A
        Slice of Life and for your letter which I am only reading today. I will
        pass the word on and try to share it with as many people as possible.
        Keep up your good spirits and your good work! Love and blessings to you.
      </blockquote>
      <p>— From Dr. Elisabeth Kubler Ross.</p>

      <blockquote>
        “As with all works of true imagination, we are taken into something
        universal….disease is taken into the soul where it is possible to see
        the transformation of a life and the birth of unselfish love.”
      </blockquote>
      <p>
        — Dr. Robert Sardello, author of <em>Facing the World with Soul</em>{" "}
        (and many other books).
      </p>

      <blockquote>
        “An inspiration to cancer patients and enjoyable reading for anyone…..
        Lee Sturgeon Day engages the big questions and life’s trivial
        absurdities with equal vigor, captivating us with her rare courage,
        wisdom and rollicking good humor.”
      </blockquote>
      <p>
        — Lois Robbins, author of <em>Waking Up in the Age of Creativity</em>
      </p>

      <blockquote>
        Last night I concluded a third complete reading of your book and I’m
        writing to say how grateful and appreciative I am for sharing your
        beautiful and painful experiences. I was first diagnosed with breast
        cancer in November l991. My son, David, was nine years old. Pamela
        Benitez was my surgeon… Thank you again for the love you poured into A
        Slice of Life. You have strengthened and enriched me. Sincerely.
      </blockquote>
      <p>— Margaret in Detroit</p>

      <p>
        <strong>Please note:</strong> mistletoe therapy is now a licensed and
        approved homeopathic remedy in the US, and can therefore be prescribed
        by any MD. Sites for pharmacies and anthroposophic physicians can be
        found on line.
      </p>
    </>
  );
}
